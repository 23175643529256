/* Define font colors and font styles at the top for easy customization */
:root {
  --primary-font: "Inter", sans-serif;
  --secondary-font: "Roboto", sans-serif;
  --primary-color: #1B263B; /* Navy Blue (Primary Color) */
  --secondary-color: #A9C0D8; /* Light Steel Blue (Secondary Color) */
  --accent-color: #3B82F6; /* Royal Blue (Accent Color for Highlights and Buttons) */
  --background-color: #E5F0F7; /* Very Light Blue Background */
  --button-color: var(--accent-color); /* Button Color */
  --button-hover-color: #2C6BB2; /* Darker Royal Blue for Button Hover */
  --overlay-background: rgba(27, 38, 59, 0.85); /* Dark Overlay with Navy Blue Tones */
}

/* General Style */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: var(--primary-font);
}

.freeman-regular {
  font-family: var(--primary-font);
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 100px;
  pointer-events: none;
  margin-top: 20px;
}

.App-header {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--primary-color);
  padding-top: 60px; /* Adjust for fixed navbar */
}

/* Navbar */
.navbar {
  width: 100%;
  background-color: var(--accent-color);
  overflow: hidden;
  position: fixed; /* Make navbar fixed */
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.navbar-logo {
  height: 50px;
  margin-left: 10px;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: right;
}

.navbar li {
  margin: 0 15px;
}

.navbar a {
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 0.75em;
  font-family: var(--secondary-font);
}

.navbar a:hover {
  background-color: var(--accent-color);
  border-radius: 5px;
}

/* Background Image and Overlay */
.background-image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh; /* Make the image take the full viewport height */
  position: relative;
}

.overlay {
  background-color: var(--overlay-background); /* Adjust transparency here */
  padding: 10px 40px; /* Reduced padding */
  color: white;
  position: absolute;
  top: 41%; /* Move the box up */
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 80%;
  min-width: 60%;
  border-radius: 20px;
}

.overlay p {
  font-size: 0.9em;
}

.overlay h1 {
  font-size: 3em;
  margin: 0.1em 0;
  font-family: var(--primary-font);
}

/* About Section */
.about {
  text-align: center;
  padding: 20px;
  color: white;
}

.about-section {
  background-color: #2b75fdb6;
  color: white;
  align-items: center;
  left: 50%;
  padding: 50px 20px;
  width: 100%;
}

/* Founder Section */
.founder-section {
  margin-top: 0px;
}

.founder-section h2 {
  margin-top: 0.5em;
  margin-bottom: 0.3em;
  font-size: 2em;
  font-family: var(--primary-font);
}

.founder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.founder-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 20px;
  margin-top: 0px;
}

.founder-details {
  text-align: center;
  margin-top: 20px;
  margin-right: 2.5px;
  margin-left: 2.5px;
}

.founder-name {
  font-size: 1.5em;
  margin-bottom: 0em;
  margin-top: 0;
  color: var(--primary-color);
}

.founder-description {
  font-size: 0.5em;
  font-weight: 400;
  color: gray;
  margin-bottom: 1em;
  margin-top: 0;
}

.school-name {
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 1.5em;
  padding-left: 0.7em;
  padding-right: 0.7em;
}

.degree-name {
  color: #4e8cfe;
  font-weight: bold;
  font-size: 1.5em;
  padding-left: 0.25em;
  padding-right: 0.25em;
}

/* Media Query for Wide Screens */
@media (min-width: 768px) {
  .founder-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .founder-image {
    margin-right: 20px;
  }

  .founder-details {
    text-align: center;
    margin-top: 0;
  }
}

/* General Headings */
h1 {
  font-size: 3em;
  margin: 0em 0;
  font-family: var(--primary-font);
}

p {
  font-size: 1em;
  margin-bottom: 1.75em;
  align-items: center;
  font-family: var(--secondary-font);
}

#about {
  font-size: 0.8em;
  margin-left: 2em;
  margin-right: 2em;
}

/* Signup Form */
.signup-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.signup-form input {
  padding: 0.5em 1.2em;
  font-size: 0.9em;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 1em;
  min-width: 50%;
}

.signup-form button {
  padding: 0.5em 1em;
  font-size: 0.9em;
  color: white;
  background-color: var(--button-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1em;
}

.signup-form button:hover {
  background-color: var(--button-hover-color);
}

/* Media Query for Small Screens */
@media (max-width: 1250px) {
  .signup-form {
    flex-direction: column;
  }

  .signup-form input {
    margin-right: 0;
    margin-bottom: 0.75em;
    padding: 0.5em 0.5em;
    font-size: 1em;
    width: 92%;
  }

  .signup-form button {
    padding: 0.5em 0.1em;
    width: 100%;
    font-size: 1.1em;
  }

  .new-section p {
    font-size: 1em;
    margin: 0 1em;
    padding: 0px;
    margin-bottom: 0.25em;
  }
}

/* Stats Section */
.stats-section {
  background-color: var(--accent-color);
  color: white;
  padding: 40px 20px;
  width: 100%;
  position: relative;
  align-items: center;
}

.stats-section h2 {
  font-size: 3em;
  width: 100%;
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}

.stats-section ul {
  list-style-type: none;
  padding: 0;
  font-size: 1.25em;
}

.stats-section li {
  font-size: 1.25em;
  margin: 10px 0;
}

/* New Section */
.new-section {
  background-color: #b3e5fc;
  color: #001e36;
  padding: 50px 20px;
  width: 100%;
}

.new-section h2 {
  font-size: 3em;
  margin-bottom: 0.25em;
  margin-top: 0.25em;
  margin-left: 0.25em;
}

.new-section p {
  font-size: 1.25em;
  margin: 0 2em;
  padding: 10px;
  margin-bottom: 0.25em;
  text-align: left; /* Change text alignment to left */
}

/* Video Section */
.stats-section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire container */
}

.stats-section .overlay {
    background-color: var(--overlay-background);
    padding: 5px 10px; /* Reduced padding */
    color: white;
    position: relative;
    top: 0; /* Move the box up */
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    border-radius: 20px;
}
