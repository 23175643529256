/* animations.css */

/* Keyframes for fadeIn effect */
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Keyframes for slideIn effect */
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Keyframes for pulse effect */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1.05);
    }
  }
  
  /* Apply fadeIn animation */
  .fade-in {
    animation: fadeIn 1s ease-out;
  }
  
  /* Apply slideIn animation */
  .slide-in {
    animation: slideIn 1s ease-out;
  }
  
  /* Apply pulse animation on hover */
  .pulse:hover {
    animation: pulse 0.5s ease-in-out;
    transform: scale(1.05); /* Slight zoom on hover */
  }
  